import React from 'react';
import BrandedSpinner from './BrandedSpinner';

export default function MainLoader() {
  return (
    <div className="flex align-middle h-screen">
      <BrandedSpinner />
    </div>
  );
}
