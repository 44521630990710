import dayjs from 'dayjs';

export const currentYear = new Date().getFullYear();
export const currentTimestamp = () => Date.now();

export const toDateStringFromTimestamp = (unixTimestamp, { customFormatString } = {}) => {
  if (!unixTimestamp) return '';

  const formatString = !customFormatString ? `MM/DD/YYYY` : customFormatString;

  return dayjs.unix(unixTimestamp).format(formatString);
};
