import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/styles';
import './components/Toast';
import Header from './components/Header';
import Footer from './components/Footer';
import history from './common/history';
import MainLoader from './components/Loaders/MainLoader';
import { useAuth0 } from './hooks/useAuth';
import { configureGraphQLClient } from './common/configureGraphQLClient';
import { routes } from './routes';
import PrivateRoute from './components/PrivateRoute';

const Home = lazy(() => import('./screens/Home'));
const SitesTable = lazy(() => import('./screens/sites/SitesTable'));
const SiteForm = lazy(() => import('./screens/sites/SiteForm'));
const PostsTable = lazy(() => import('./screens/posts/PostsTable'));
const ImportPosts = lazy(() => import('./screens/posts/ImportPosts'));
const PostForm = lazy(() => import('./screens/posts/PostForm'));
const FourOhFour = lazy(() => import('./screens/FourOhFour'));

const theme = createMuiTheme({ palette: { secondary: { main: green[500] } } });

export default function App() {
  const { loading, getIdTokenClaims } = useAuth0();

  if (loading) return <MainLoader />;

  const client = configureGraphQLClient(getIdTokenClaims);

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Suspense fallback={<div />}>
          <Router history={history}>
            <Header />
            <main id="content" className="relative">
              <Switch>
                <Route path={routes.home} exact component={Home} />
                <PrivateRoute path={routes.sites} exact component={SitesTable} />
                <PrivateRoute path={routes.addSite} exact component={SiteForm} />
                <PrivateRoute path={`${routes.sites}/:siteId`} exact component={SiteForm} />
                <PrivateRoute path={`${routes.sites}/:siteId/posts`} exact component={PostsTable} />
                <PrivateRoute path={`${routes.sites}/:siteId/import`} exact component={ImportPosts} />
                <PrivateRoute path={`${routes.sites}/:siteId/create`} exact component={PostForm} />
                <PrivateRoute path={`${routes.sites}/:siteId/:postId`} exact component={PostForm} />
                <Route component={FourOhFour} />
              </Switch>
            </main>
            <Footer />
          </Router>
        </Suspense>
      </ApolloProvider>
    </ThemeProvider>
  );
}
