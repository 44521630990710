import React from 'react';
import { useAuth0 } from '../hooks/useAuth';
import Button from '../components/Button';
import './headerStyles.css';
import useRouter from '../hooks/useRouter';
import { routes } from '../routes';

function NavBar() {
  const { isAuthenticated, logout, loginWithPopup } = useAuth0();
  const { location } = useRouter();

  return (
    <div className="flex justify-between items-center py-4 px-8 bg-primary shadow">
      <h2 className="text-white text-xl">ZeyVentures</h2>
      {!isAuthenticated && (
        <Button type="button" buttonColor="bg-white text-gray-800" onClick={() => loginWithPopup()}>
          Login
        </Button>
      )}
      {isAuthenticated && location.pathname === routes.sites && (
        <Button type="button" buttonColor="bg-white text-gray-800" onClick={() => logout()}>
          Logout
        </Button>
      )}
    </div>
  );
}

export default NavBar;
