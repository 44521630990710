import React from 'react';
import { currentYear } from '../common/dateFunctions';

export default function Footer() {
  return (
    <footer
      className="
      m-auto text-center
      text-xs
      bg-white
      text-gray-600
      py-2
      "
    >
      Copyright {currentYear} Zey Ventures
    </footer>
  );
}
