import React from 'react';
import './brandedSpinnerStyles.css';

export default function BrandedSpinner() {
  return (
    <div className="branded-spinner m-auto">
      <div className="logo" />
      <div className="loader" />
      <div className="text-sm text-gray-800 mt-6 pb-12 ">Loading...</div>
    </div>
  );
}
