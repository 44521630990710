import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from './hooks/useAuth';
import config from './auth_config.json';
import history from './common/history';
import './tailwind.css';

// A function that routes the user to the right place after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);
