import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

export const configureGraphQLClient = (getIdTokenClaims) => {
  const httpLink = createHttpLink({ uri: 'https://tlkhkql735.execute-api.us-east-1.amazonaws.com/prod/graphql' });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIdTokenClaims();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.__raw}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};
