import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BeatLoader from 'react-spinners/BeatLoader';

export default function Button(props) {
  // eslint-disable-next-line
  const { children, to, tiny, loading, className, buttonColor, textColor, primary, secondary, ...restOfProps } = props;

  let color = '';
  if (secondary) {
    color = `bg-secondary text-white hover:bg-secondaryHover`;
  } else {
    color = `${buttonColor} ${textColor}`;
  }

  let size = tiny ? 'px-2 py-1 text-xs md:px-3 md:py-1 md:text-sm' : 'px-3 py-1 text-sm md:px-5 md:py-2 md:text-base';

  const cssClassNames = `shadow antialiased leading-tight rounded ${size} ${color} ${className} hover:shadow disabled:opacity-50 disabled:cursor-not-allowed`;

  if (to) {
    return (
      <Link className={cssClassNames} {...restOfProps} to={to}>
        {!loading ? children : <BeatLoader size={8} sizeUnit="px" color="white" />}
      </Link>
    );
  }

  /* eslint-disable */
  return (
    <button disabled={loading} className={cssClassNames} {...restOfProps}>
      {!loading ? children : <BeatLoader size={8} sizeUnit="px" color="white" />}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
};

Button.defaultProps = {
  to: '',
  className: '',
  loading: false,
  buttonColor: 'bg-gray-800 hover:bg-gray-600',
  textColor: 'text-white',
};
